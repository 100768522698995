<template>
  <div class="fc-g" v-if="loading">正在获取促销活动信息…</div>
  <div class="fc-g" v-else-if="!data || !data.length">目前还没有可参加的促销活动。</div>
  <div v-else>
    <el-tabs v-model="selectedId">
      <el-tab-pane v-for="p in data" :key="p.id" :name="p.id">
        <div class="h c" slot="label">
          <div style="font-size: 12px; color: white; background-color: #F56C6C; border-radius: 2px; line-height: 1; padding: 3px 5px;">{{methods[p.method]}}</div>
          <span>&nbsp;{{p.name}}</span>
        </div>
      </el-tab-pane>
    </el-tabs>

    <promotion-detail :supplier="supplier" :button-text="buttonText" :promotion="selected" @submit="handleGoodsSelect"></promotion-detail>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import promotionDetail from "./promotionDetail";

export default {
  components: { promotionDetail },
  mixins: [initData],
  props: {
    supplier: String | Number,
    buttonText: {
      type: String,
      default: "立即采购",
    },
  },
  data() {
    return {
      selected: null,
      selectedId: null,
      methods: {
        discount: "打折",
        save: "减价",
        price: "一口价",
        gift: " 赠品",
      },
    };
  },
  watch: {
    selectedId: "handleSelectChange",
  },
  methods: {
    beforeInit() {
      this.selectedId = null;
      this.selected = null;
      this.url = "api/promotion/available";
      this.params = Object.assign({}, this.query || {}, {
        sellerId: this.supplier,
      });
      this.inited = true;
      return true;
    },
    afterLoad(res) {
      res.forEach((o) => {
        o._loading = false;
        o._details = null;
      });
      if (res.length) {
        this.selectedId = res[0].id;
      }
    },
    handleSelectChange(id) {
      if (!id) return false;
      let row = this.data.find((d) => {
        return d.id === id;
      });
      if (!row) return false;
      this.selected = row;
    },
    handleGoodsSelect(goods) {
      if (goods && goods.length) {
        goods.forEach((g) => {
          g.fromPromotion = true;
        });
        this.$emit("submit", goods);
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>