<template>
  <div>
    <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="editable ? isAdd ? '新增采购单' : '编辑采购单' : '查看采购单'" custom-class="custom-dialog-max-width" @closed="handleClosed">
      <template v-if="form">
        <template v-if="editable">
          <el-form ref="form" :model="form" :rules="rules" label-width="84px" label-position="right" label-suffix=":">
            <div class="h s">
              <el-form-item prop="sellerId" label="供应商">
                <quick-select v-model="form.sellerId" filterable url="api/purchase/seller" value-field="belongTo" display-field="belongToName" auto-select-first-option @change="handleSellerChange" style="width: 180px;" />
              </el-form-item>
              <el-form-item prop="purchaseTime" label="采购日期" label-width="100px">
                <el-date-picker v-model="form.purchaseTime" :clearable="false" value-format="timestamp" style="width: 180px;" />
              </el-form-item>
              <el-form-item label="交货日期" label-width="100px">
                <el-date-picker v-model="defaultShipDate" :clearable="false" value-format="timestamp" style="width: 180px;" />
              </el-form-item>
            </div>

            <el-form-item style="margin-bottom: 8px !important;">
              <div class="h e">
                <sku-selector v-if="form.sellerId" request-url="api/purchaseGoods" :request-params="{entId: form.sellerId}" :exclude-keys="excludeGoods" @submit="handleGoodsAdd" />
                <group-sale-selector v-if="form.sellerId" :supplier="form.sellerId" @submit="handleGroupSaleAdd" style="margin-left: 10px;" />
                <el-button size="small" type="primary" @click="promotionVisible = true" style="margin-left: 10px;">促销活动</el-button>
              </div>
            </el-form-item>
            <el-form-item label="采购商品" prop="items">
              <el-table :data="form.items" empty-text="还没有添加要采购的商品" class="table-outdent">
                <el-table-column prop="code" label="商品编码" width="150" />
                <el-table-column prop="erpCode" label="ERP编码" width="150" />
                <el-table-column label="商品" min-width="240">
                  <div style="line-height: 1.5" slot-scope="scope">
                    <div>{{scope.row.goodsName}}</div>
                    <div class="fc-g">{{$goodsSpecConvert(scope.row.goodsSpec)}}</div>
                    <el-dropdown szie="mini" placement="bottom-start" @command="handleGoodsPromotionSet(scope.row, $event)" style="width: 100%;" v-if="scope.row._promotions && scope.row._promotions.length">
                      <div style="line-height: 1.5" class="h c fc-e fs-mini">
                        <div class="flex">促销活动：{{getGoodsPromotionInfo(scope.row)}}</div>
                        <i class="el-icon-arrow-down"></i>
                      </div>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="p in scope.row._promotions" :key="p.id" :command="p.id" style="min-width: 240px;">
                          <div class="h c fs-mini" :class="p.id === scope.row.promotionId ? 'fc-p' : ''">
                            <div class="flex">促销活动：{{p._label}}&emsp;&emsp;</div>
                            <div class="fa fa-check fc-p" v-if="p.id === scope.row.promotionId"></div>
                          </div>
                        </el-dropdown-item>
                        <el-dropdown-item divided :command="null">不参与促销活动</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </el-table-column>
                <el-table-column label="期待发货时间" width="155">
                  <template slot-scope="scope">
                    <el-date-picker v-model="scope.row.shipDate" :clearable="false" size="mini" value-format="timestamp" style="width: 100%" />
                  </template>
                </el-table-column>
                <el-table-column label="当前库存" width="100">
                  <template slot-scope="scope">
                    <div v-if="scope.row._stockInfo.loading" class="fc-g">正在计算中…</div>
                    <div v-else-if="scope.row._stockInfo.error" class="h c">
                      <span class="fc-e">{{scope.row._stockInfo.error}}</span>
                      <el-button size="mini" type="text" icon="el-icon-refresh" @click="loadStockInfo(scope.row)"></el-button>
                    </div>
                    <div v-else class="h c">
                      <b>{{scope.row._stockInfo.total}}</b>
                      <el-popover placement="bottom" width="400" trigger="click" v-if="scope.row._stockInfo.items && scope.row._stockInfo.items.length">
                        <el-table :data="scope.row._stockInfo.items">
                          <el-table-column min-width="160" property="warehouseName" label="库房"></el-table-column>
                          <el-table-column min-width="80" align="right" property="count" label="数量"></el-table-column>
                        </el-table>
                        <el-button size="mini" type="text" slot="reference" style="padding: 0 0 3px 5px !important;">明细</el-button>
                      </el-popover>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="单价" width="100" align="right">
                  <template slot-scope="scope">
                    <div v-if="scope.row._price.loading" class="fc-g">正在获取中…</div>
                    <div v-else-if="scope.row._price.error" class="h c">
                      <span class="fc-e">{{scope.row._price.error}}</span>
                      <a href="javascript:void(0)" @click="loadGoodsPrice(scope.row)">
                        &nbsp;
                        <i class="el-icon-refresh"></i>
                      </a>
                    </div>
                    <div style="line-height: 1.5" class="v e" v-else>
                      <b class="fc-e">{{$price(scope.row._price.value)}}</b>
                      <span class="fc-g through" v-if="scope.row._price.oldValue > scope.row._price.value">{{$price(scope.row._price.oldValue)}}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="数量" width="110" align="center">
                  <template slot-scope="scope">
                    <el-input-number :ref="`item_quantity_${scope.row._id}`" v-model="scope.row.count" :min="scope.row.minSalesQuantity || 0.01" :max="9999" :step="1" :precision="2" size="mini" controls-position="right" :disabled="scope.row._price.loading" @change="$checkNaN(scope.row, 'count', scope.row.minSalesQuantity || 0.01, loadGoodsPrice)" style="width: 100%;" />
                  </template>
                </el-table-column>
                <el-table-column label="备注" width="160">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.info" size="mini" :maxlength="100" />
                  </template>
                </el-table-column>
                <el-table-column width="50">
                  <div class="row-commands" slot-scope="scope">
                    <!-- <el-button type="text" size="mini" @click="handleGoodsRemove(scope.row)">移除</el-button> -->
                    <a href="javascript:void(0)" class="fc-e el-icon-delete" @click="handleGoodsRemove(scope.row)"></a>
                  </div>
                </el-table-column>
              </el-table>
            </el-form-item>
            <el-form-item label="赠品列表" v-if="form._gifts && form._gifts.length" style="margin-top: -14px;">
              <el-table :data="form._gifts" :show-header="false" row-class-name="common-gift-goods-row" class="table-outdent">
                <el-table-column prop="current.code" label="商品编码" width="150" />
                <el-table-column prop="current.erpCode" label="ERP编码" width="150" />
                <el-table-column label="商品" min-width="240">
                  <template slot-scope="scope">
                    <div style="line-height: 1.5" v-if="scope.row.goodsList.length === 1">
                      <div>{{scope.row.current.goodsName}}</div>
                      <div class="fc-g">{{$goodsSpecConvert(scope.row.current.goodsSpec)}}</div>
                    </div>
                    <el-dropdown placement="bottom-start" @command="handleGiftSet(scope.row, $event)" style="width: 100%;" v-else>
                      <div class="h c fs-mini">
                        <div style="line-height: 1.5" class="flex">
                          <div>{{scope.row.current.goodsName}}</div>
                          <div class="fc-g">{{$goodsSpecConvert(scope.row.current.goodsSpec)}}</div>
                        </div>
                        <i class="el-icon-arrow-down"></i>
                      </div>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="g in scope.row.goodsList" :key="g.id" :command="g.id" style="min-width: 240px;">
                          <div class="fs-mini h c" :class="g.id === scope.row.giftId ? 'fc-p' : ''">
                            <div class="flex">{{g.name}}（{{$goodsSpecConvert(g.specs)}}）&nbsp;×&nbsp;{{g.quantity}}&emsp;&emsp;</div>
                            <div class="fa fa-check fc-p" v-if="g.id === scope.row.giftId"></div>
                          </div>
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                </el-table-column>
                <el-table-column label="期待发货时间" width="155">
                  <template slot-scope="scope">
                    <el-date-picker v-model="scope.row.current.shipDate" :clearable="false" size="mini" value-format="timestamp" style="width: 100%" />
                  </template>
                </el-table-column>
                <el-table-column label="当前库存" width="100">
                  <template slot-scope="scope">
                    <div v-if="scope.row.current._stockInfo.loading" class="fc-g">正在计算中…</div>
                    <div v-else-if="scope.row.current._stockInfo.error" class="h c">
                      <span class="fc-e">{{scope.row.current._stockInfo.error}}</span>
                      <el-button size="mini" type="text" icon="el-icon-refresh" @click="loadStockInfo(scope.row.current)"></el-button>
                    </div>
                    <div v-else class="h c">
                      <b>{{scope.row.current._stockInfo.total}}</b>
                      <el-popover placement="bottom" width="400" trigger="click" v-if="scope.row.current._stockInfo.items && scope.row.current._stockInfo.items.length">
                        <el-table :data="scope.row.current._stockInfo.items">
                          <el-table-column min-width="160" property="warehouseName" label="库房"></el-table-column>
                          <el-table-column min-width="80" align="right" property="count" label="数量"></el-table-column>
                        </el-table>
                        <el-button size="mini" type="text" slot="reference" style="padding: 0 0 3px 5px !important;">明细</el-button>
                      </el-popover>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="单价" width="100" align="right">
                  <template slot-scope="scope">
                    <b class="fc-e">{{$price(scope.row.current.realPrice)}}</b>
                  </template>
                </el-table-column>
                <el-table-column prop="current.count" label="数量" width="110" align="center" />
                <el-table-column label="备注" width="160" />
                <el-table-column width="50" />
              </el-table>
            </el-form-item>
            <el-form-item label="组合销售" v-if="form._groupSales && form._groupSales.length">
              <template v-for="g in form._groupSales">
                <div class="group-sale" :key="g.id">
                  <div class="h c">
                    <div class="flex padding-0-10 fs-small">
                      <b>{{g.groupSaleName}}</b>
                      <span class="padding-0-10" v-if="g.discountAmount > 0">(优惠: {{$price(g.discountAmount)}})</span>
                    </div>
                    <div class="padding-10" style="width: 260px;">
                      <el-input-number size="mini" v-model="g.times" :min="g.minTimes" :max="99" :step="1" :precision="0" :controls="false" @change="handleGroupSaleTimesChange(g)" style="width: 80px;" />
                    </div>
                    <div class="padding-10" style="width: 50px;">
                      <div class="row-commands">
                        <!-- <el-button type="text" size="mini" @click="handleGroupSaleRemove(g)">移除</el-button> -->
                        <a href="javascript:void(0)" class="fc-e el-icon-delete" @click="handleGroupSaleRemove(g)"></a>
                      </div>
                    </div>
                  </div>
                  <el-table :data="g.items" :show-header="false">
                    <el-table-column prop="code" label="商品编码" width="150" />
                    <el-table-column prop="erpCode" label="ERP编码" width="150" />
                    <el-table-column label="商品" min-width="240">
                      <div style="line-height: 1.5" slot-scope="scope">
                        <div>{{scope.row.goodsName}}</div>
                        <div class="fc-g">{{$goodsSpecConvert(scope.row.goodsSpec)}}</div>
                      </div>
                    </el-table-column>
                    <!-- <el-table-column prop="goodsBrand" label="品牌" width="120" /> -->
                    <el-table-column label="期待发货时间" width="155">
                      <template slot-scope="scope">
                        <el-date-picker v-model="scope.row.shipDate" :clearable="false" size="mini" value-format="timestamp" style="width: 100%" />
                      </template>
                    </el-table-column>
                    <el-table-column label="当前库存" width="100">
                      <template slot-scope="scope">
                        <div v-if="scope.row._stockInfo.loading" class="fc-g">正在计算中…</div>
                        <div v-else-if="scope.row._stockInfo.error" class="h c">
                          <span class="fc-e">{{scope.row._stockInfo.error}}</span>
                          <el-button size="mini" type="text" icon="el-icon-refresh" @click="loadStockInfo(scope.row)"></el-button>
                        </div>
                        <div v-else class="h c">
                          <b>{{scope.row._stockInfo.total}}</b>
                          <el-popover placement="bottom" width="400" trigger="click" v-if="scope.row._stockInfo.items && scope.row._stockInfo.items.length">
                            <el-table :data="scope.row._stockInfo.items">
                              <el-table-column min-width="160" property="warehouseName" label="库房"></el-table-column>
                              <el-table-column min-width="80" align="right" property="count" label="数量"></el-table-column>
                            </el-table>
                            <el-button size="mini" type="text" slot="reference" style="padding: 0 0 3px 5px !important;">明细</el-button>
                          </el-popover>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="单价" width="100" align="right">
                      <template slot-scope="scope">
                        <div v-if="scope.row._price.loading" class="fc-g">正在获取中…</div>
                        <div v-else-if="scope.row._price.error" class="h c">
                          <span class="fc-e">{{scope.row._price.error}}</span>
                          <a href="javascript:void(0)" @click="loadGoodsPrice(scope.row)">
                            &nbsp;
                            <i class="el-icon-refresh"></i>
                          </a>
                        </div>
                        <div class="v e" style="line-height: 1.5" v-else>
                          <b class="fc-e">{{$price(scope.row._price.value)}}</b>
                          <span class="fc-g through" v-if="scope.row._price.oldValue > scope.row._price.value">{{$price(scope.row._price.oldValue)}}</span>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="count" label="数量" width="110" align="center" />
                    <el-table-column label="备注" width="160">
                      <template slot-scope="scope">
                        <el-input v-model="scope.row.info" size="mini" :maxlength="100" />
                      </template>
                    </el-table-column>
                    <el-table-column width="50" />
                  </el-table>
                </div>
              </template>
            </el-form-item>
            <div class="h">
              <div class="flex">
                <el-form-item label="整单优惠">
                  <quick-select v-model="form.promotionId" :options="promotions" display-field="_label" :disabled="promotionLoading" clearable empty-text="没有可以参与的促销活动" placeholder="请选择促销活动" @change="loadPromotions" style="width: 460px;" />
                </el-form-item>
                <el-form-item prop="payType" label="支付方式">
                  <el-radio-group v-model="form.payType">
                    <el-radio-button :label="0" v-if="enablePayType !== 'offline'">线上支付</el-radio-button>
                    <el-radio-button :label="1" v-if="enablePayType !== 'online'">线下支付</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div class="v e">
                <div class="h c">
                  <template v-if="typeof totalMoney === 'number'">
                    <template v-if="orderDiscount > 0">
                      <form-info-item label="商品金额" style="margin-right: 10px;">
                        <b>{{$price(totalMoney)}}</b>
                      </form-info-item>
                      <form-info-item label="优惠金额" style="margin-right: 10px;">
                        <b class="fc-e">{{$price(orderDiscount)}}</b>
                      </form-info-item>
                    </template>
                    <form-info-item label="订单金额">
                      <b>{{$price(totalMoney - orderDiscount)}}</b>
                    </form-info-item>
                  </template>
                  <form-info-item label="订单金额" v-else>
                    <b>{{totalMoney}}</b>
                  </form-info-item>
                </div>
                <form-info-item>
                  <div class="h c">
                    <el-checkbox v-model="form.useRebate">使用返利抵扣{{canUsedRebateStr}}</el-checkbox>
                    <el-popover placement="top" width="360" trigger="hover">
                      <div>
                        返利抵扣金额在订单生成后由系统自动计算，当订单的商品、数量、参加的促销活动等进行更改后，返利抵扣金额也可能发生变动。
                        <br />
                        <span class="fc-e">当前显示的返利抵扣可抵金额仅供参考，请以提交订单后为准。</span>
                      </div>
                      <i class="el-icon-question" style="margin-left: 10px;" slot="reference"></i>
                    </el-popover>
                  </div>
                </form-info-item>
              </div>
            </div>
            <div class="h s">
              <el-form-item label="收货人" prop="receiverName">
                <el-input v-model="form.receiverName" :maxlength="50" style="width: 180px;" />
              </el-form-item>
              <el-form-item prop="receiverPhone" label="联系电话" label-width="100px">
                <el-input v-model="form.receiverPhone" :maxlength="25" style="width: 260px;" />
              </el-form-item>
              <el-form-item label-width="10px">
                <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/distributor/contact" empty-text="还没有常用收货人信息，可在“基础数据/收货信息”中添加。" @change="handleContactChange">
                  <el-table-column prop="name" label="姓名" width="100" />
                  <el-table-column prop="mobilePhone" label="手机号码" width="150" />
                  <el-table-column prop="officePhone" label="办公室电话" width="150" />
                </datagrid-picker>
              </el-form-item>
            </div>
            <div class="h s">
              <el-form-item label="收货地址" prop="recProvinceCode">
                <quick-select v-model="form.recProvinceCode" :label.sync="form.recProvince" url="api/regionalDict/province" value-field="code" placeholder="选择省份" filterable style="width: 180px;" @change="form.recCityCode = null; form.recDistrictCode = null;" />
              </el-form-item>
              <el-form-item label-width="10px" prop="recCityCode" style="width: 180px;" v-if="form.recProvinceCode">
                <quick-select v-model="form.recCityCode" :label.sync="form.recCity" :url="`api/regionalDict/parent/${form.recProvinceCode}`" value-field="code" placeholder="选择城市" filterable style="width: 100%;" @change="form.recDistrictCode = null" />
              </el-form-item>
              <el-form-item label-width="10px" style="width: 180px;" v-else>
                <el-input disabled placeholder="请先选择省份" />
              </el-form-item>
              <el-form-item label-width="10px" prop="recDistrictCode" style="width: 180px;" v-if="form.recCityCode">
                <quick-select v-model="form.recDistrictCode" :label.sync="form.recDistrict" :url="`api/regionalDict/parent/${form.recCityCode}`" value-field="code" placeholder="选择地区" filterable style="width: 100%;" />
              </el-form-item>
              <el-form-item label-width="10px" style="width: 180px;" v-else>
                <el-input disabled placeholder="请先选择城市" />
              </el-form-item>
              <el-form-item label-width="10px" prop="recAddress" class="flex">
                <el-input v-model="form.recAddress" :maxlength="100" class="flex" placeholder="请输入详细地址" />
              </el-form-item>
              <el-form-item label-width="10px">
                <datagrid-picker :reference="true" reference-icon="fas fa-mouse-pointer" url="api/distributor/address" empty-text="还没有常用收货地址信息，可在“基础数据/收货信息”中添加。" @change="handleAddressChange">
                  <el-table-column prop="provinceName" label="省" width="100" />
                  <el-table-column prop="cityName" label="市" width="150" />
                  <el-table-column prop="districtName" label="区" width="150" />
                  <el-table-column prop="address" label="详细地址" width="300" />
                </datagrid-picker>
              </el-form-item>
            </div>
            <el-form-item prop="info" label="摘要">
              <el-input type="textarea" v-model="form.info" :maxlength="200" placeholder="有其他要求或需要说明的情况，请在备注中说明" resize="none" :rows="3" />
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="text" @click="dialog = false">取消</el-button>
            <el-button :loading="saving" :disabled="submiting" type="primary" @click="doSave">保存</el-button>
            <el-button :loading="submiting" :disabled="saving" type="danger" @click="doSubmit" v-if="!isAdd && form.payType === 1">保存并提交</el-button>
          </div>
        </template>
        <template v-else>
          <el-form ref="form" :model="form" label-width="68px" label-suffix=":" label-position="right">
            <div class="h sb">
              <div>
                <form-info-item label="采购单号">{{form.formCode}}</form-info-item>
                <form-info-item label="ERP单号" v-if="form.erpFormCode && form.erpFormCode !== form.formCode">{{form.erpFormCode}}</form-info-item>
                <form-info-item label="采购时间">{{new Date(form.purchaseTime).format()}}</form-info-item>
              </div>
              <div>
                <form-info-item label="供应商">{{form.seller.name}}</form-info-item>
                <form-info-item label="收货人">{{form.receiverName}}（{{form.receiverPhone}}）</form-info-item>
                <form-info-item label="收货地址">{{form.recProvince}}{{form.recCity}}{{form.recDistrict}}{{form.recAddress}}</form-info-item>
              </div>
            </div>
            <el-table border :data="form.items" empty-text="当前采购单没有任何商品" style="margin: 8px 0;">
              <el-table-column prop="code" label="商品编码" min-width="150" />
              <el-table-column prop="erpCode" label="ERP编码" min-width="150" />
              <el-table-column label="商品" min-width="240">
                <div class="h" style="line-height: 1.5" slot-scope="scope">
                  <template v-if="scope.row.isGift">
                    <el-tag size="mini" type="danger">赠</el-tag>&emsp;
                  </template>
                  <div>
                    <div>{{scope.row.goodsName}}</div>
                    <div class="fc-g">{{$goodsSpecConvert(scope.row.goodsSpec)}}</div>
                    <template v-if="scope.row.promotionId">
                      <div class="fc-e" v-if="scope.row.promotionJoinType === 'groupSale'">组合销售：{{scope.row.groupSaleName}}</div>
                      <div class="fc-e" v-else>促销活动：{{scope.row.promotionName}}</div>
                    </template>
                  </div>
                </div>
              </el-table-column>
              <el-table-column label="期待发货时间" width="100" :formatter="r => { return new Date(r.shipDate).format('yyyy/MM/dd'); }" />
              <el-table-column label="当前库存" width="90">
                <template slot-scope="scope">
                  <div v-if="scope.row._stockInfo.loading" class="fc-g">正在计算中…</div>
                  <div v-else-if="scope.row._stockInfo.error" class="h c">
                    <span class="fc-e">{{scope.row._stockInfo.error}}</span>
                    <el-button size="mini" type="text" icon="el-icon-refresh" @click="loadStockInfo(scope.row)"></el-button>
                  </div>
                  <div v-else class="h c">
                    <b>{{scope.row._stockInfo.total}}</b>
                    <el-popover placement="bottom" width="400" trigger="click" v-if="scope.row._stockInfo.items && scope.row._stockInfo.items.length">
                      <el-table :data="scope.row._stockInfo.items">
                        <el-table-column min-width="160" property="warehouseName" label="库房"></el-table-column>
                        <el-table-column min-width="100" align="right" property="count" label="数量"></el-table-column>
                      </el-table>
                      <el-button size="mini" type="text" slot="reference" style="padding: 0 0 3px 5px !important;">明细</el-button>
                    </el-popover>
                  </div>
                </template>
              </el-table-column>

              <el-table-column label="单价" width="240" align="right">
                <div slot-scope="scope">
                  <b class="fc-e">{{$price(scope.row._finalPrice)}}</b>
                  <div class="fc-g h e r lh-100 ta-c" v-if="scope.row.price > scope.row._finalPrice" v-html="scope.row._finalPrices"></div>
                </div>
              </el-table-column>
              <el-table-column prop="count" label="数量" align="center" width="70" />
              <el-table-column prop="sendCount" label="已发货" align="center" width="70" />
              <el-table-column prop="receivedCount" label="已收货" align="center" width="70" />
              <el-table-column prop="cancelCount" label="已取消" align="center" width="70" />
              <el-table-column prop="_realRebateAmount" label="返利抵扣" align="right" width="100" :formatter="$price" v-if="form.status !== 0 && form.status !== 1 && form.status !== 3" />
              <el-table-column label="小计" width="100" align="right">
                <template slot-scope="scope">
                  <b class="fc-e">{{$price(scope.row._finalAmount)}}</b>
                </template>
              </el-table-column>
              <el-table-column prop="sourceCode" label="对应订单" min-width="120" align="center" />
              <el-table-column prop="info" label="备注" min-width="120" />
            </el-table>
            <div class="h s">
              <form-info-item class="flex" label="摘要">{{form.info}}</form-info-item>
              <form-info-item label="支付方式" style="margin-right: 50px;">{{payTypes[form.payType]}}</form-info-item>
              <template v-if="form.discountAmount > 0">
                <form-info-item label="商品金额" style="margin-right: 50px;">
                  <b>{{$price(form.totalMoney)}}</b>
                </form-info-item>
                <form-info-item label="优惠金额" style="margin-right: 50px;">
                  <b class="fc-e">{{$price(form.discountAmount)}}</b>
                </form-info-item>
              </template>
              <form-info-item label="返利抵扣" style="margin-right: 50px;" v-if="form.useRebate && form.rebateAmount > 0">
                <b class="fc-e">{{$price((form.rebateAmount || 0))}}(已确认：{{$price((form.realRebateAmount || 0))}})</b>
              </form-info-item>
              <form-info-item label="应付金额" content-style="padding-right: 10px;">
                <b>{{$price((form.realTotalMoney || 0) - (form.realRebateAmount || 0))}}</b>
              </form-info-item>
            </div>
            <el-divider />
            <div class="h c">
              <form-info-item label="当前状态" style="margin-right: 50px;">
                <dot same :type="status[form.status].type">{{status[form.status].label}}</dot>
              </form-info-item>
              <form-info-item label="付款情况" v-if="checkPermission(['PURCHASE_ALL','PURCHASE_PAY'])">
                <dot same :type="payStatus[form.payType][form.payStatus].type">{{payStatus[form.payType][form.payStatus].label}}</dot>
              </form-info-item>
              <div class="flex"></div>
              <el-button type="text" @click="dialog = false">关闭</el-button>
              <el-button type="primary" @click="doPrint">打印采购单</el-button>
            </div>
          </el-form>
        </template>
      </template>
      <template v-else-if="loading">
        <div v-loading="loading" style="height: 100px"></div>
      </template>
    </el-dialog>

    <el-dialog :visible.sync="promotionVisible" title="促销活动" append-to-body width="1080px" v-if="editable">
      <promotion-selector v-if="form.sellerId" :supplier="form.sellerId" @submit="handleGoodsAdd" />
    </el-dialog>
  </div>
</template>

<script>
import {
  get,
  add,
  edit,
  submit,
  getPurchaseGoodsPrice,
} from "@/api/purchaseOrder";
import { getSet } from "@/api/capitalPool";
import { getDefaultAddress, getDefaultContact } from "@/api/receiving";
import { getGoodsById } from "@/api/warehouse";
import { mapGetters } from "vuex";
import request from "@/utils/request";
import skuSelector from "@/views/assembly/skuSelect";
import groupSaleSelector from "@/views/assembly/groupSaleSelect";
import promotionSelector from "@/views/assembly/promotionSelect";
import checkPermission from "@/utils/permission";

export default {
  components: { skuSelector, groupSaleSelector, promotionSelector },
  data() {
    return {
      isAdd: false,
      loading: false,
      saving: false,
      submiting: false,
      dialog: false,
      form: null,
      defaultContact: null,
      defaultAddress: null,
      defaultShipDate: null,
      maxRebate: 0,
      payTypes: ["线上支付", "线下支付"],
      enablePayType: "all",
      rebateRule: null,
      payStatus: [
        [
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "warning",
            label: "部分付款成功",
          },
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "danger",
            label: "付款失败",
          },
          {
            type: "primary",
            label: "付款成功",
          },
        ],
        [
          {
            type: "info",
            label: "待付款",
          },
          {
            type: "warning",
            label: "部分已付款",
          },
          {
            type: "warning",
            label: "部分付款已确认",
          },
          {
            type: "success",
            label: "已付款",
          },
          {
            type: "danger",
            label: "付款失败",
          },
          {
            type: "primary",
            label: "已完成",
          },
        ],
      ],
      status: [
        {
          type: "info",
          label: "编辑中",
        },
        {
          type: "warning",
          label: "已提交",
        },
        {
          type: "success",
          label: "已确认",
        },
        {
          type: "danger",
          label: "已拒绝",
        },
        {
          type: "warning",
          label: "待发货",
        },
        {
          type: "success",
          label: "部分已发货",
        },
        {
          type: "success",
          label: "已发货",
        },
        {
          type: "success",
          label: "部分已收货",
        },
        {
          type: "success",
          label: "已完成",
        },
        {
          type: "info",
          label: "已关闭",
        },
        {
          type: "info",
          label: "已取消",
        },
        {
          type: "info",
          label: "部分已关闭",
        },
      ],
      goodsQueryDefine: {
        name: "",
      },
      rules: {
        sellerId: [{ required: true, message: "请选择供应商" }],
        purchaseTime: [{ required: true, message: "请选择采购日期" }],
        payType: [{ required: true, message: "请选择支付方式" }],
        receiverName: [{ required: true, message: "请填写收货人" }],
        receiverPhone: [{ required: true, message: "请填写收货人联系电话" }],
        recProvinceCode: [{ required: true, message: "请选择省份" }],
        recCityCode: [{ required: true, message: "请选择城市" }],
        recDistrictCode: [{ required: true, message: "请选择地区" }],
        recAddress: [{ required: true, message: "请填写详细地址" }],
      },
      promotions: [],
      promotionLoading: true,
      promotionVisible: false,
      gifts: {},
    };
  },
  computed: {
    ...mapGetters(["user"]),
    excludeGoods() {
      return (this.form.items || []).map((o) => {
        return o.goodsId;
      });
    },
    totalMoney() {
      let c = 0,
        e = "";
      if (this.form) {
        for (let i = 0, l = this.form.items.length; i < l; i++) {
          let o = this.form.items[i];
          if (typeof o.count !== "number" || o.count < 0) o.count = 0;
          if (!o._price.loading && !o._price.error) {
            c += o._price.oldValue * o.count;
          } else {
            e = "正在获取中…";
            break;
          }
        }
        if (!e && this.form._groupSales && this.form._groupSales.length) {
          for (let i = 0, l = this.form._groupSales.length; i < l; i++) {
            let g = this.form._groupSales[i];
            for (let j = 0, jl = g.items.length; j < jl; j++) {
              let o = g.items[j];
              if (typeof o.count !== "number" || o.count < 1) o.count = 1;
              if (!o._price.loading && !o._price.error) {
                c += o._price.oldValue * o.count;
              } else {
                e = "正在获取中…";
                break;
              }
            }
            if (e) break;
          }
        }
        if (!e && this.form._gifts && this.form._gifts.length) {
          for (let o of this.form._gifts) {
            c += o.current.realPrice * o.current.count;
          }
        }
      }
      if (e) {
        return e;
      } else {
        return c;
      }
    },
    orderDiscount() {
      let dc = 0;
      if (this.form) {
        if (
          this.form.promotionId &&
          this.promotions &&
          this.promotions.length
        ) {
          let p = this.promotions.find((o) => {
            return o.id === this.form.promotionId;
          });
          if (p) {
            dc += p.discountAmount;
          }
        }

        (this.form.items || []).forEach((o) => {
          if (o.promotionId && o.promotionJoinType === "goods") {
            let p = o._promotions.find((p) => {
              return p.id === o.promotionId;
            });
            if (p) dc += p.discountAmount;
          }
        });

        (this.form._groupSales || []).forEach((g) => {
          let gdc = 0;
          g.items.forEach((o) => {
            if (!o._price.loading && !o._price.error) {
              let c = (o._price.oldValue - o._price.value) * o.count;
              dc += c;
              gdc += c;
            }
          });
          g.discountAmount = gdc;
        });
      }

      return dc;
    },
    canUsedRebateStr() {
      let r = 0,
        s = 0;
      if (this.rebateRule) {
        s = this.rebateRule.canUserBalance;
        if (s < 0) s = 0;
        if (!isNaN(this.totalMoney) && !isNaN(this.orderDiscount)) {
          let m = this.totalMoney - this.orderDiscount;
          if (m < 0) m = 0;
          if (m >= this.rebateRule.minMoney) {
            if (this.rebateRule.limitRaito > 0) {
              r = Math.round((m * this.rebateRule.limitRaito) / 10000);
            } else {
              r = m;
            }
            if (
              this.rebateRule.limitMoney > 0 &&
              r > this.rebateRule.limitMoney
            )
              r = this.rebateRule.limitMoney;
            if (r > s) r = s;
            if (r > m) r = m;
          }
        }
      }
      return `（可抵：${this.$price(r)} / 剩余：${this.$price(s)}）`;
    },
    editable() {
      return this.form && this.form.status === 0;
    },
  },
  methods: {
    checkPermission,
    handleClosed() {
      this.form = null;
      this.rebateRule = null;
      this.maxRebate = null;
    },
    convertForm() {
      return new Promise((resolve, reject) => {
        let form = null,
          hasNoSelectPromotion =
            this.promotions && this.promotions.length && !this.form.promotionId,
          err = null;
        if (this.form.items.length || this.form._groupSales.length) {
          form = JSON.parse(JSON.stringify(this.form));
          let hasPromotionsItem = false,
            hasSelect = false;
          for (let i = 0, l = form.items.length; i < l; i++) {
            let o = form.items[i];
            o.times = 1;
            if (!o._price || o._price.error) {
              err = `商品：${o.goodsName}【${this.$goodsSpecConvert(
                o.goodsSpec
              )}】没有价格，无法采购`;
              break;
            }
            if (
              !this.form.promotionId &&
              o._promotions &&
              o._promotions.length
            ) {
              hasPromotionsItem = true;
              if (o.promotionId) {
                hasSelect = true;
              }
            }
            delete o._price;
            delete o._stockInfo;
            delete o._promotions;
            delete o._finalPrice;
            delete o._finalPrices;
            delete o._finalAmount;
            delete o._id;
          }

          if (!hasNoSelectPromotion && hasPromotionsItem && !hasSelect) {
            hasNoSelectPromotion = true;
          }

          if (!err) {
            for (let i = 0, l = form._groupSales.length; i < l; i++) {
              let g = form._groupSales[i];
              for (let j = 0, jl = g.items.length; j < jl; j++) {
                let o = g.items[j];
                o.times = g.times;
                if (!o._price || o._price.error) {
                  err = `商品：${o.goodsName}【${this.$goodsSpecConvert(
                    o.goodsSpec
                  )}】没有价格，无法采购`;
                  break;
                } else {
                  delete o._price;
                  delete o._stockInfo;
                  delete o.groupSaleRule;
                  form.items.push(o);
                }
              }
              if (err) break;
            }
          }
          if (!err) {
            delete form._groupSales;

            form._gifts.forEach((o) => {
              if (o.current) {
                delete o.current._price;
                delete o.current._stockInfo;
                form.items.push(o.current);
              }
            });

            delete form._gifts;
          }
        } else {
          reject("请选择商品或者组合销售");
        }
        if (err) {
          reject(err);
        } else if (hasNoSelectPromotion) {
          this.$confirm(
            "当前订单还有促销活动或者整单优惠活动可以参加。<br />确定不参与活动并继续操作吗？",
            "温馨提示",
            {
              type: "warning",
              dangerouslyUseHTMLString: true,
            }
          )
            .then((_) => {
              resolve(form);
            })
            .catch((_) => {
              reject();
            });
        } else {
          resolve(form);
        }
      });
    },
    doSave(callback) {
      this.$refs.form &&
        this.$refs.form.validate().then((_) => {
          this.convertForm()
            .then((form) => {
              this.saving = true;
              (this.isAdd ? add : edit)(form)
                .then((res) => {
                  delete res.items;
                  if (this.isAdd)
                    Object.assign(this.form, {
                      id: res.id,
                    });
                  this.isAdd = false;
                  this.$parent.init();
                  if (typeof callback === "function") callback();
                  else
                    this.$notify({
                      title: `保存采购单成功`,
                      type: "success",
                      duration: 2500,
                    });
                })
                .finally((_) => {
                  this.saving = false;
                });
            })
            .catch((err) => {
              if (err) this.$alert(err, "系统提示");
            });
        });
    },
    doSubmit() {
      if (this.form && this.form.id) {
        this.$confirm(
          "提交采购单后将不能再进行修改，确定要提交吗？",
          "提交确认",
          {
            type: "warning",
            dangerouslyUseHTMLString: true,
          }
        ).then((res) => {
          this.doSave((_) => {
            this.submiting = true;
            submit(this.form.id)
              .then((res) => {
                this.$notify({
                  title: `提交采购单成功`,
                  type: "success",
                  duration: 2500,
                });
                this.dialog = false;
                this.$parent.init();
              })
              .finally((_) => {
                this.submiting = false;
              });
          });
        });
      }
    },
    doPrint() {
      if (this.form && this.form.id) this.$print("purchase", this.form.id);
    },
    loadStockInfo(g) {
      if (g && g.goodsId) {
        g._stockInfo.loading = true;
        g._stockInfo.error = false;
        getGoodsById(g.goodsId)
          .then((res) => {
            let count = 0;
            (res || []).forEach((ro) => {
              count += ro.count;
            });
            g._stockInfo.total = count;
            g._stockInfo.items = res;
          })
          .catch((err) => {
            g._stockInfo.error = "获取失败";
          })
          .finally((_) => {
            g._stockInfo.loading = false;
          });
      }
    },
    loadGoodsPrice(g) {
      if (g && g.goodsId && !isNaN(g.count)) {
        g._price.loading = true;
        g._price.error = false;
        g._price.oldValue = null;
        g._price.value = null;
        getPurchaseGoodsPrice({
          goodsId: g.goodsId,
          quantity: g.count,
          sellerId: this.form.sellerId,
        })
          .then((res) => {
            let v = res.goodsPrice;
            if (typeof v !== "number") {
              g._price.error = "没有价格";
            } else if (g.promotionId) {
              if (g.promotionJoinType === "groupSale" && g.groupSaleRule) {
                if (g.groupSaleRule.method === "price") {
                  v = g.groupSaleRule.price;
                } else if (g.groupSaleRule.method === "save") {
                  v -= g.groupSaleRule.saveAmount;
                } else if (g.groupSaleRule.method === "discount") {
                  v = Math.round(v * g.groupSaleRule.discount);
                }
              }
            }
            g._price.oldValue = res.goodsPrice;
            g._price.value = v < 0 ? 0 : v;
            g._price.loading = false;
            if (g.promotionJoinType !== "groupSale") {
              this.loadPromotions();
            }
          })
          .catch((err) => {
            g._price.error = "获取失败";
            g._price.loading = false;
          });
      }
    },
    handleSellerChange() {
      this.form.items = [];
      if (this.form.sellerId) {
        this.loadEnablePayType();
        this.loadRebateRule();
      }
    },
    loadEnablePayType() {
      request({
        url: "api/distributor/payType/" + this.form.sellerId,
        method: "get",
      }).then((res) => {
        this.enablePayType = res;
        if (res === "offline") {
          this.form.payType = 1;
        } else if (res === "online") {
          this.form.payType = 0;
        }
      });
    },
    loadRebateRule() {
      if (this.form) {
        getSet(this.form.sellerId, this.form.id).then((res) => {
          this.rebateRule = res;
        });
      }
    },
    handleContactChange(ov, nv, row) {
      if (row) {
        let phones = [];
        if (row.mobilePhone) phones.push(row.mobilePhone);
        if (row.officePhone) phones.push(row.officePhone);

        this.form.receiverName = row.name;
        this.form.receiverPhone = phones.join(", ");
      }
    },
    handleAddressChange(ov, nv, row) {
      if (row) {
        Object.assign(this.form, {
          recProvinceCode: row.provinceCode,
          recProvince: row.provinceName,
          recCityCode: row.cityCode,
          recCity: row.cityName,
          recDistrictCode: row.districtCode,
          recDistrict: row.districtName,
          recAddress: row.address,
        });
      }
    },
    resetForm(id, action) {
      this.promotions = [];
      let now = new Date();
      this.defaultShipDate = this.$now.getDiffDays(7);

      if (id) {
        this.isAdd = false;
        this.loading = true;
        get(id)
          .then((res) => {
            if (action === "copy") {
              this.isAdd = true;
              res = {
                id: null,
                sellerId: res.sellerId,
                info: res.info,
                recProvince: res.recProvince,
                recProvinceCode: res.recProvinceCode,
                recCity: res.recCity,
                recCityCode: res.recCityCode,
                recDistrict: res.recDistrict,
                recDistrictCode: res.recDistrictCode,
                recAddress: res.recAddress,
                receiverName: res.receiverName,
                receiverPhone: res.receiverPhone,
                purchaseTime: now.getTime(),
                useRebate: res.useRebate,
                items: res.items,
                promotionId: null,
                proWholeOrderId: null,
                _groupSales: [],
                _gifts: [],
                payType: res.payType,
                status: 0,
              };
            }
            let arrs = [],
              gs = {},
              gsIds = {},
              gss = [],
              grSets = {};
            if (res.items && res.items.length) {
              res.items.forEach((o) => {
                if (action === "copy") {
                  o.shipDate = this.defaultShipDate;
                  delete o.id;
                  delete o.formId;
                  delete o.sendCount;
                  delete o.cancelCount;
                  delete o.closeTime;
                  delete o.closeUser;
                  delete o.receivedCount;
                  delete o.thisTimeCount;
                }
                o._id = this.$uuid();
                o.businessId = this.$uuid();

                let _ps = [`<div>原价<br />${this.$price(o.price)}</div>`];
                o._finalPrice = o.price;
                if (o.discountAmount) {
                  let _dp = Math.round(o.discountAmount / o.count);
                  o._finalPrice -= _dp;
                  _ps.push(`<div>促销优惠<br />${this.$price(_dp)}</div>`);
                }

                o._realRebateAmount = 0;
                if (o.rebateAmount) {
                  let _rp = Math.round(o.rebateAmount / o.count);
                  o._finalPrice -= _rp;
                  o._realRebateAmount = _rp * (o.count - o.cancelCount);
                  _ps.push(`<div>返利抵扣<br />${this.$price(_rp)}</div>`);
                }
                o._finalAmount = o._finalPrice * (o.count - o.cancelCount);
                o._finalPrices = _ps.join("<div>&nbsp;-&nbsp;</div>");

                o._stockInfo = {
                  loading: false,
                  error: false,
                  total: 0,
                  items: [],
                };
                o._price = {
                  loading: false,
                  error: false,
                  oldValue: o.price,
                  value: o.realPrice,
                };
                o._promotions = [];
                if (
                  res.status === 0 &&
                  o.promotionId &&
                  o.promotionJoinType === "groupSale"
                ) {
                  if (!gs.hasOwnProperty(o.groupId)) {
                    gs[o.groupId] = {
                      ids: [],
                      groupSaleId: o.groupSaleId,
                      groupId: o.groupId,
                      groupSaleName: o.groupSaleName,
                      times: o.times,
                      minTimes: o.groupMinBuyQuantity,
                      discountAmount: 0,
                      items: [],
                    };
                    gss.push(gs[o.groupId]);
                  }
                  o.groupSaleRule.quantity = o.count / o.times;
                  gs[o.groupId].ids.push(
                    o.groupSaleItemGoodsId +
                      "_" +
                      o.groupSaleRule.quantity.toFixed(2)
                  );
                  gs[o.groupId].items.push(o);
                } else if (
                  res.status === 0 &&
                  o.promotionId &&
                  o.promotionJoinType === "wholeOrder"
                ) {
                  o.promotionId = null;
                  o.singleGoodsGroupId = null;
                  o.singleGoodsLadderId = null;
                  o.promotionName = null;
                  o.promotionJoinType = null;
                  arrs.push(o);
                } else if (res.status !== 0 || !o.isGift) {
                  o.groupId = o.groupId || this.$uuid();
                  arrs.push(o);
                } else {
                  grSets[o.giftRuleId] = o;
                }
                this.loadStockInfo(o);
              });
            }
            gss.forEach((g) => {
              g.id = g.groupSaleId + "," + g.ids.sort().join(",");
            });
            res.items = arrs;
            res._groupSales = gss;
            res._gifts = [];
            this.gifts = grSets;

            this.form = res;
            this.$nextTick((_) => {
              if (this.editable) {
                this.loadPromotions();
                this.loadEnablePayType();
                this.loadRebateRule();
              }
            });
          })
          .finally((_) => {
            this.loading = false;
          });
      } else if (this.user) {
        this.isAdd = true;
        this.form = Object.assign(
          {
            id: null,
            sellerId: null,
            info: "",
            recProvince: null,
            recProvinceCode: null,
            recCity: null,
            recCityCode: null,
            recDistrict: null,
            recDistrictCode: null,
            recAddress: null,
            receiverName: this.user.userRealName,
            receiverPhone: this.user.phone,
            purchaseTime: new Date().getTime(),
            useRebate: true,
            items: [],
            promotionId: null,
            proWholeOrderId: null,
            _groupSales: [],
            _gifts: [],
            payType: 0,
            status: 0,
          },
          this.defaultAddress
            ? {
                recProvinceCode: this.defaultAddress.provinceCode,
                recProvince: this.defaultAddress.provinceName,
                recCityCode: this.defaultAddress.cityCode,
                recCity: this.defaultAddress.cityName,
                recDistrictCode: this.defaultAddress.districtCode,
                recDistrict: this.defaultAddress.districtName,
                recAddress: this.defaultAddress.address,
              }
            : {},
          this.defaultContact
            ? {
                receiverName: this.defaultContact.name,
                receiverPhone: this.defaultContact.phones,
              }
            : {}
        );
      }
      this.dialog = true;
    },
    handleGoodsAdd(list) {
      if (list && list.length) {
        let now = new Date(this.$now.get());
        this.form.items = (this.form.items || []).concat(
          list.map((o) => {
            let _no = {
              _id: this.$uuid(),
              goodsId: o.id,
              goodsSpec: o.specs,
              goodsName: o.name,
              minSalesQuantity: o.minSalesQuantity || 0,
              code: o.code,
              erpCode: o.erpCode,
              groupId: this.$uuid(),
              _price: {
                loading: false,
                error: false,
                oldValue: null,
                value: null,
              },
              shipDate: this.$now.getDiffDays(o.deliveryCycle || 7),
              count: o.minSalesQuantity || 1,
              info: "",
              _stockInfo: {
                loading: false,
                error: false,
                total: 0,
                items: [],
              },
              _promotions: [],
              businessId: this.$uuid(),
              promotionId: null,
              singleGoodsGroupId: null,
              singleGoodsLadderId: null,
            };
            this.loadStockInfo(_no);
            this.loadGoodsPrice(_no);
            return _no;
          })
        );
        this.$refs.form && this.$refs.form.clearValidate();
      }
    },
    handleGoodsRemove(row) {
      let inx = this.form.items.indexOf(row);
      if (inx >= 0) {
        this.form.items.splice(inx, 1);
        if (this.form.items.length) {
          this.loadPromotions();
        } else {
          this.form.promotionId = null;
          this.promotions = [];
          this.form._gifts = [];
        }
      }
    },
    loadPromotions() {
      let items = [],
        able = true;
      for (let i = 0, l = this.form.items.length; i < l; i++) {
        let o = this.form.items[i];
        if (!o._price || o._price.loading || o._price.error) {
          able = false;
          break;
        } else {
          items.push({
            id: o.id,
            goodsId: o.goodsId,
            price: o._price.oldValue,
            businessId: o.businessId,
            promotionId:
              o.promotionJoinType !== "wholeOrder" ? o.promotionId : null,
            count: o.count,
          });
        }
      }
      if (able) {
        this.promotionLoading = true;
        request({
          url: "api/order/promotion",
          method: "post",
          data: {
            sellerId: this.form.sellerId,
            promotionId: this.form.promotionId,
            items: items,
          },
        })
          .then((res) => {
            if (res) {
              let oPromotion = null;
              res.promotions.forEach((o) => {
                if (o.discountAmount > 0) {
                  o._label =
                    o.name + " (优惠: " + this.$price(o.discountAmount) + ")";
                } else {
                  o._label = o.name;
                }
                if (this.form.promotionId === o.id) oPromotion = o;
              });
              this.promotions = res.promotions;
              this.form.promotionId = null;
              this.form.proWholeOrderId = null;
              if (
                oPromotion &&
                oPromotion.wholeOrderDetails &&
                oPromotion.wholeOrderDetails.length
              ) {
                let d = oPromotion.wholeOrderDetails[0];
                if (d) {
                  this.form.promotionId = oPromotion.id;
                  this.form.proWholeOrderId = d.groupId;
                }
              }

              if (res.orderItems && res.orderItems.length) {
                res.orderItems.forEach((o) => {
                  let g = this.form.items.find((fo) => {
                    return fo.businessId === o.businessId;
                  });
                  if (g) {
                    o.promotions.forEach((o1) => {
                      if (o1.discountAmount > 0) {
                        o1._label =
                          o1.name +
                          " (优惠: " +
                          this.$price(o1.discountAmount) +
                          ")";
                      } else {
                        o1._label = o1.name;
                      }
                    });
                    g._price.value =
                      o.realPrice === null ? g._price.oldValue : o.realPrice;
                    g._promotions = o.promotions;
                    g.singleGoodsGroupId = null;
                    g.singleGoodsLadderId = null;
                    g.promotionId = o.promotionId;
                    g.promotionJoinType = o.promotionJoinType;
                    if (g.promotionId) {
                      if (g.promotionJoinType === "goods") {
                        let p = o.promotions.find((p) => {
                          return p.id === g.promotionId;
                        });
                        if (p && p.rules && p.rules.length) {
                          let r = p.rules[0];
                          g.singleGoodsGroupId = r.groupId;
                          g.singleGoodsLadderId = r.ladderId;
                        } else {
                          g.promotionId = null;
                        }
                      } else if (g.promotionJoinType === "wholeOrder") {
                        let p = this.promotions.find((p) => {
                          return p.id === g.promotionId;
                        });
                        if (
                          p &&
                          p.wholeOrderDetails &&
                          p.wholeOrderDetails.length
                        ) {
                          let r = p.wholeOrderDetails[0];
                          g.singleGoodsGroupId = r.groupId;
                          g.singleGoodsLadderId = r.ladderId;
                        } else {
                          g.promotionId = null;
                        }
                      }
                    }
                  }
                });
              }
            }

            this.calcGifts();
          })
          .finally((_) => {
            this.promotionLoading = false;
          });
      }
    },
    handleGoodsPromotionSet(row, promotionId) {
      if (row.promotionId !== promotionId) {
        // 联动更改其它参与同一活动的商品的活动ID
        this.form.items.forEach((o) => {
          if (
            o.businessId !== row.businessId &&
            o.promotionId &&
            o.promotionId === row.promotionId &&
            o.singleGoodsGroupId === row.singleGoodsGroupId &&
            o.singleGoodsLadderId === row.singleGoodsLadderId
          ) {
            o.promotionId = promotionId;
            o.promotionJoinType = "goods";
          }
        });
        row.promotionId = promotionId;
        row.promotionJoinType = "goods";
        this.loadPromotions();
      }
    },
    getGoodsPromotionInfo(row) {
      if (row) {
        if (row.promotionId && row.promotionJoinType === "goods") {
          let p = row._promotions.find((o) => {
            return o.id === row.promotionId;
          });
          if (p) {
            return p._label;
          } else {
            row.promotionId = null;
          }
        }
      }
      return "点击选择促销活动";
    },
    setOrderPromotion(p) {
      let able = false;
      if (p && p.wholeOrderDetails && p.wholeOrderDetails.length) {
        let d = p.wholeOrderDetails[0];
        if (d) {
          able = true;
          this.form.proWholeOrderId = d.groupId;
        }
      }
      if (!able) {
        this.form.promotionId = null;
        this.form.proWholeOrderId = null;
      }
      this.calcGifts();
    },
    convertGiftGoods(g) {
      let go = {
        _id: this.$uuid(),
        promotionId: g.promotionId,
        giftId: g.id,
        giftRuleId: g.giftRuleId,
        code: g.code,
        erpCode: g.erpCode,
        goodsId: g.goodsId,
        goodsSpec: g.specs,
        goodsName: g.name,
        price: g.price,
        realPrice: g.price,
        isGift: true,
        shipDate: this.$now.getDiffDays(g.deliveryCycle || 7),
        count: g.quantity,
        info: "",
        _stockInfo: {
          loading: false,
          error: false,
          total: 0,
          items: [],
        },
      };
      this.loadStockInfo(go);
      return go;
    },
    calcGifts() {
      let gifts = [];

      let buildGift = (p, gr) => {
        let o = {
          promotionId: gr.promotionId,
          promotionName: p.name,
          groupId: gr.groupId,
          ladderId: gr.ladderId,
          giftRuleId: gr.id,
          goodsList: gr.goodsList,
          giftId: null,
          current: null,
        };
        let exist = null;
        if (this.gifts.hasOwnProperty(gr.id)) {
          exist = (gr.goodsList || []).find((g) => {
            return g.id === this.gifts[gr.id].giftId;
          });
          if (exist) {
            this.gifts[gr.id].count = exist.quantity;
            o.current = this.gifts[gr.id];
            o.giftId = o.current.giftId;
          }
        }
        if (!exist && gr.goodsList.length) {
          o.current = this.convertGiftGoods(gr.goodsList[0]);
          o.giftId = o.current.giftId;
          this.gifts[gr.id] = o.current;
        }
        gifts.push(o);
      };

      if (this.form.promotionId && this.promotions && this.promotions.length) {
        let p = this.promotions.find((o) => {
          return o.id === this.form.promotionId;
        });
        if (p && p.method === "gift" && p.giftRules && p.giftRules.length) {
          p.giftRules.forEach((gr) => {
            buildGift(p, gr);
          });
        }
      }

      if (this.form.items && this.form.items.length) {
        this.form.items.forEach((item) => {
          if (item.promotionId) {
            let p = item._promotions.find((o) => {
              return o.id === item.promotionId;
            });
            if (p && p.method === "gift" && p.giftRules && p.giftRules.length) {
              p.giftRules.forEach((gr) => {
                buildGift(p, gr);
              });
            }
          }
        });
      }
      this.form._gifts = gifts;
      this.calcGiftPromotionDiscounts();
    },
    handleGiftSet(giftGroup, giftId) {
      if (giftId !== giftGroup.giftId) {
        let g = giftGroup.goodsList.find((o) => {
          return o.id === giftId;
        });
        if (g) {
          giftGroup.giftId = giftId;
          giftGroup.current = this.convertGiftGoods(g);
        } else {
          giftGroup.giftId = null;
          giftGroup.current = null;
        }
        this.calcGiftPromotionDiscounts();
      }
    },
    calcGiftPromotionDiscounts() {
      let calcedPromotionIds = [];
      this.form._gifts.forEach((group) => {
        if (calcedPromotionIds.indexOf(group.promotionId) < 0) {
          calcedPromotionIds.push(group.promotionId);
          let giftAmounts = 0,
            itemAmounts = 0;
          this.form._gifts.forEach((t) => {
            if (t.promotionId === group.promotionId && t.current) {
              giftAmounts += t.current.price * t.current.count;
            }
          });
          let items = this.form.items.filter((o) => {
            if (o.promotionId === group.promotionId) {
              itemAmounts += o._price.oldValue * o.count;
              return true;
            }
            return false;
          });
          let remainGiftAmounts = giftAmounts;
          for (let i = 0, l = items.length; i < l; i++) {
            let o = items[i];
            let p = null;
            if (o.promotionJoinType === "goods") {
              p = o._promotions.find((_p) => {
                return _p.id === group.promotionId;
              });
            } else if (o.promotionJoinType === "wholeOrder") {
              p = this.promotions.find((_p) => {
                return _p.id === group.promotionId;
              });
            }
            if (p) {
              if (i === l - 1) {
                o._price.value = Math.round(
                  o._price.oldValue - remainGiftAmounts / o.count
                );
                p.discountAmount = remainGiftAmounts;
              } else {
                let perAmount = Math.round(
                  (giftAmounts *
                    ((o._price.oldValue * o.count) / itemAmounts)) /
                    o.count
                );
                p.discountAmount = perAmount * o.count;
                o._price.value = o._price.oldValue - perAmount;
                remainGiftAmounts -= p.discountAmount;
              }
            } else {
              o._price.value = o._price.oldValue;
            }
          }
        }
      });
    },
    handleGroupSaleAdd(sel) {
      let list = sel.items;
      if (list && list.length) {
        let sid = "";
        let ids = list
          .map((o) => {
            sid = o.groupSaleId;
            return o.id + "_" + o.count.toFixed(2);
          })
          .sort()
          .join(",");
        ids = sid + "," + ids;
        let exist = this.form._groupSales.find((o) => {
          return o.id === ids;
        });
        if (exist) {
          exist.times++;
          exist.items.forEach((o) => {
            o.count += o.groupSaleRule.quantity;
            this.loadGoodsPrice(o);
          });
        } else {
          let g = {
            id: ids,
            groupId: this.$uuid(),
            groupSaleName: "",
            discountAmount: 0,
            times: sel.minBuyQuantity,
            minTimes: sel.minBuyQuantity,
            items: [],
          };
          list.forEach((o) => {
            g.groupSaleName = o.groupSaleName;
            let _no = {
              _id: this.$uuid(),
              goodsId: o.goodsId,
              goodsSpec: o.spec,
              goodsName: o.goodsName,
              minSalesQuantity: o.groupSaleRule.quantity * sel.minBuyQuantity,
              code: o.code,
              erpCode: o.erpCode,
              _price: {
                loading: false,
                error: false,
                oldValue: null,
                value: null,
              },
              shipDate: this.$now.getDiffDays(o.deliveryCycle || 7),
              count: o.groupSaleRule.quantity * sel.minBuyQuantity,
              info: "",
              _stockInfo: {
                loading: false,
                error: false,
                total: 0,
                items: [],
              },
              groupId: g.groupId,
              groupMinBuyQuantity: sel.minBuyQuantity,
              promotionId: o.promotionId,
              promotionJoinType: "groupSale",
              groupSaleId: o.groupSaleId,
              groupSaleItemGoodsId: o.id,
              groupSaleName: o.groupSaleName,
              groupSaleRule: o.groupSaleRule,
            };

            this.loadStockInfo(_no);
            this.loadGoodsPrice(_no);
            g.items.push(_no);
          });
          this.form._groupSales.push(g);
        }
      }
    },
    handleGroupSaleTimesChange(g) {
      if (typeof g.times !== "number") {
        this.$nextTick((_) => {
          let o = g.items[0];
          g.times = o.count / o.groupSaleRule.quantity;
        });
      } else {
        g.items.forEach((o) => {
          o.count = o.groupSaleRule.quantity * g.times;
          this.loadGoodsPrice(o);
        });
      }
    },
    handleGroupSaleRemove(row) {
      let inx = this.form._groupSales.indexOf(row);
      if (inx >= 0) {
        this.form._groupSales.splice(inx, 1);
      }
    },
  },
  mounted() {
    getDefaultContact().then((res) => {
      if (res) {
        let phones = [];
        if (res.mobilePhone) phones.push(res.mobilePhone);
        if (res.officePhone) phones.push(res.officePhone);
        res.phones = phones.join(", ");
      }
      this.defaultContact = res;
    });
    getDefaultAddress().then((res) => {
      this.defaultAddress = res;
    });
  },
};
</script>

<style lang="less" scoped>
.group-sale {
  background-color: #eeeaff;
  &:first-child {
    margin-top: -8px;
  }
}
</style>