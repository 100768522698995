import request from '@/utils/request'

let combine = {
  get: function (id) {
    return request({
      url: 'api/groupSales/getById/' + id,
      method: 'get'
    })
  },
  getItem: function (id) {
    return request({
      url: 'api/promotion/groupSaleItem/getById/' + id,
      method: 'get'
    })
  },
  add: function (data) {
    return request({
      url: 'api/groupSales',
      method: 'post',
      data
    })
  },
  edit: function (data) {
    return request({
      url: 'api/groupSales',
      method: 'put',
      data
    })
  },
  del: function (id) {
    return request({
      url: 'api/groupSales/' + id,
      method: 'delete'
    })
  },
  start: function (id) {
    return request({
      url: "api/groupSales/start/" + id,
      method: "put"
    })
  },
  stop: function (id) {
    return request({
      url: "api/groupSales/stop/" + id,
      method: "put"
    })
  },
  // copy: function (id) {
  //   return request({
  //     url: 'api/groupSales/copy/'+id,
  //     method: 'post'
  //   })
  // },
};

let promotion = {
  get: function (id) {
    return request({
      url: 'api/singleGoods/getById/' + id,
      method: 'get'
    })
  },
  add: function (data) {
    return request({
      url: 'api/singleGoods',
      method: 'post',
      data
    })
  },
  edit: function (data) {
    return request({
      url: 'api/singleGoods',
      method: 'put',
      data
    })
  },
  // copy: function (id) {
  //   return request({
  //     url: 'api/singleGoods/copy/'+id,
  //     method: 'post'
  //   })
  // },
  del: function (id) {
    return request({
      url: 'api/singleGoods/' + id,
      method: 'delete'
    })
  },
  start: function (id) {
    return request({
      url: "api/singleGoods/start/" + id,
      method: "put"
    })
  },
  stop: function (id) {
    return request({
      url: "api/singleGoods/stop/" + id,
      method: "put"
    })
  }
};

let order = {
  get: function (id) {
    return request({
      url: 'api/wholeOrder/getById/' + id,
      method: 'get'
    })
  },
  add: function (data) {
    return request({
      url: 'api/wholeOrder',
      method: 'post',
      data
    })
  },
  edit: function (data) {
    return request({
      url: 'api/wholeOrder',
      method: 'put',
      data
    })
  },
  del: function (id) {
    return request({
      url: 'api/wholeOrder/' + id,
      method: 'delete'
    })
  },
  start: function (id) {
    return request({
      url: "api/wholeOrder/start/" + id,
      method: "put"
    })
  },
  stop: function (id) {
    return request({
      url: "api/wholeOrder/stop/" + id,
      method: "put"
    })
  },
  // copy: function (id) {
  //   return request({
  //     url: 'api/wholeOrder/copy/'+id,
  //     method: 'post'
  //   })
  // },
};

export { combine, promotion, order }; 