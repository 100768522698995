<template>
  <div>
    <el-button :type="buttonType" :size="buttonSize" @click="open" v-if="showButton">{{buttonText}}</el-button>

    <el-dialog :visible.sync="dialog" :title="title" append-to-body :close-on-click-modal="false" @closed="handleClosed" width="1200px">
      <div class="fc-g" style="margin-bottom: 12px;" v-if="tip">{{tip}}</div>
      <div class="h s">
        <div style="width: 40%; margin-right: 20px;">
          <div style="margin-bottom: 10px;">
            <b>组合销售列表</b>
          </div>
          <div class="h" style="margin-bottom: 12px;">
            <el-input v-model="query.name" @keypress.enter.native="toQuery" @clear="toQuery" :validate-event="false" clearable placeholder="输入名称进行搜索" style="width: 240px;">
              <el-button icon="el-icon-search" slot="append" @click.stop="toQuery" />
            </el-input>
          </div>
          <el-table ref="list" row-key="id" :data="data" border highlight-current-row @current-change="handleSelectChange">
            <el-table-column prop="name" label="名称" />
            <el-table-column prop="minBuyQuantity" label="起订量" width="80" />
          </el-table>
        </div>
        <div class="flex">
          <div style="margin-bottom: 10px;">
            <b>组合销售明细</b>
          </div>
          <template v-if="selected">
            <div class="fc-g" v-if="selected._loading">正在获取组合销售的明细，请稍候…</div>
            <template v-else>
              <div class="fc-e padding-10-0" v-if="selected._details.disableType ==='allQuantity'">当前组合为限量销售，目前已经售罄</div>
              <div class="fc-e padding-10-0" v-else-if="selected._details.disableType ==='maxTimes'">当前组合每个用户限购{{selected.maxTimes}}套，您不可再进行购买</div>
              <div class="h c sb padding-10-0">
                <span>起订量：{{selected._details.minBuyQuantity}}</span>
                <span v-if="selected._details.allQuantity > 0">剩余数量：{{selected._details.allQuantity}}</span>
              </div>
              <div v-for="(o, i) in selected._details.items" :key="o.id" style="margin-bottom: 10px; border: #ececec solid 1px;">
                <div class="padding-10 h c sb fs-small" style="background-color: #f2f2f2;">
                  <span>内容{{i+1}}</span>
                  <span>需要以下商品的购买数量总和为：{{o.quantity}}</span>
                </div>
                <el-table :data="o.goodsList" row-key="goodsId" style="margin-bottom: -1px;">
                  <el-table-column label="商品">
                    <template slot-scope="scope">
                      <span>{{scope.row.goodsName}}</span>
                      <span class="fc-g">（{{$goodsSpecConvert(scope.row.spec)}}）</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="折扣" key="discount" width="80" v-if="o.method === 'discount'">
                    <template slot-scope="scope">{{Math.round(o.discount * 1000) / 100}}折</template>
                  </el-table-column>
                  <el-table-column prop="saveAmount" label="单价优惠" key="save" width="120" :formatter="$price" v-else-if="o.method === 'save' && checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])" />
                  <el-table-column prop="price" label="单价一口价" key="save" width="120" :formatter="$price" v-else-if="o.method === 'price' && checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY']) " />
                  <el-table-column label="购买数量" width="150" v-if="!disabled">
                    <template slot-scope="scope">
                      <el-input-number size="mini" v-model="scope.row._quantity" :min="0" :max="o.quantity" :step="1" :precision="2" @change="$checkNaN(scope.row, '_quantity', 0)" style="width: 100%" />
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <!-- <div v-for="row in selected._details" :key="row.id" style="margin-bottom: 10px; border: #ececec solid 1px; padding: 3px; border-radius: 4px;">
                <form-info-item label="商品名称" label-width="78px">{{row.goodsName}}</form-info-item>
                <form-info-item label="组合优惠" label-width="78px">
                  <span v-if="selected.method === 'discount'">{{Math.round(row.discount * 1000) / 100}}折</span>
                  <span v-else-if="selected.method === 'save'">单价优惠{{$price(row.saveAmount)}}</span>
                  <span v-else-if="selected.method === 'price'">单价一口价{{$price(row.price)}}</span>
                </form-info-item>
                <form-info-item label="商品数量" label-width="78px">{{row.quantity}}</form-info-item>
                <form-info-item label="选择规格" label-width="78px" v-if="row.goodsType === 'spu'">
                  <el-radio-group size="mini" v-model="row._skuId">
                    <el-radio v-for="o in row.skus" :key="o.id" :label="o.id">{{$goodsSpecConvert(o.specs)}}</el-radio>
                  </el-radio-group>
                </form-info-item>
                <form-info-item label="商品规格" label-width="78px" v-else>{{$goodsSpecConvert(row.spec)}}</form-info-item>
              </div>-->
            </template>
          </template>
          <div class="fc-g" v-else>请选择左侧的组合销售。</div>
        </div>
      </div>
      <div slot="footer">
        <el-button type="primary" :disabled="disabled || uncommitted" @click="handleSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import request from "@/utils/request";
import checkPermission from "@/utils/permission";
export default {
  mixins: [initData],
  props: {
    showButton: {
      type: Boolean,
      default: true,
    },
    buttonType: {
      type: String,
      default: "primary",
    },
    buttonSize: {
      type: String,
      default: "small",
    },
    buttonText: {
      type: String,
      default: "选择组合销售",
    },
    supplier: String | Number,
    title: {
      type: String,
      default: "选择组合销售",
    },
    tip: String,
  },
  computed: {
    disabled() {
      if (
        this.selected &&
        this.selected._details &&
        this.selected._details.disableType === "normal"
      ) {
        return false;
      }
      return true;
    },
    uncommitted() {
      if (this.selected) {
        if (!this.selected._loading) {
          if (
            this.selected._details &&
            this.selected._details.items &&
            this.selected._details.items.length
          ) {
            return (
              this.selected._details.items.filter((o) => {
                let qs = 0;
                o.goodsList.forEach((g) => {
                  qs += g._quantity;
                });
                return qs !== o.quantity;
              }).length > 0
            );
          }
        }
      }
      return true;
    },
  },
  data() {
    return {
      dialog: false,
      inited: false,
      selected: null,
    };
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.selected = null;
      this.url = "api/promotion/groupSale";
      this.params = Object.assign({}, this.query || {}, {
        sellerId: this.supplier,
      });
      this.inited = true;
      return true;
    },
    afterLoad(res) {
      res.forEach((o) => {
        o._loading = false;
        o._details = null;
      });
    },
    handleSelectChange(row) {
      this.selected = row;
      if (row && !row._loading && !row._details) {
        row._loading = true;
        request({
          url: "api/promotion/groupSale/detail/" + row.id,
          method: "get",
        })
          .then((res) => {
            res.items.forEach((o) => {
              if (o.goodsList && o.goodsList.length) {
                o.goodsList.forEach((g) => {
                  g._quantity = 0;
                });
                o.goodsList[0]._quantity = o.quantity;
              }
            });
            row._details = res;
          })
          .finally((_) => {
            row._loading = false;
          });
      }
    },
    handleClosed() {
      this.selected = null;
      this.$refs.list && this.$refs.list.setCurrentRow();
    },
    open() {
      this.dialog = true;
      if (!this.inited) {
        this.init();
      }
    },
    handleSubmit() {
      if (
        this.selected &&
        this.selected._details &&
        this.selected._details.items &&
        this.selected._details.items.length
      ) {
        let arrs = [],
          error = false;
        this.selected._details.items.forEach((d) => {
          let rq = 0;
          d.goodsList.forEach((g) => {
            if (g._quantity > 0) {
              rq += g._quantity;
              arrs.push(
                Object.assign(g, {
                  count: g._quantity,
                  promotionJoinType: "groupSale",
                  promotionMutex: this.selected._details.mutex,
                  groupSaleId: this.selected.id,
                  groupSaleName: this.selected.name,
                  groupSaleRule: {
                    method: d.method,
                    discount: d.discount,
                    saveAmount: g.saveAmount,
                    price: g.price,
                    quantity: g._quantity,
                  },
                })
              );
            }
          });
          if (rq !== d.quantity) {
            error = true;
          }
        });
        if (error) {
          this.$message.error("当前商品选择数量未满足要求");
        } else {
          this.$emit("submit", {
            minTimes: this.selected.minBuyQuantity || 1,
            maxTimes: this.selected._details.allQuantity,
            items: arrs,
          });
          this.dialog = false;
        }
      }
    },
  },
};
</script>