<template>
  <div>
    <el-table ref="list" row-key="id" :data="data" :show-header="type === 'group'" border>
      <template v-if="type === 'group'">
        <el-table-column key="t_code" prop="code" label="商品编码" min-width="130" />
        <el-table-column key="t_erpcode" prop="erpCode" label="ERP编码" min-width="130" />
        <el-table-column key="t_goods_name" label="商品名称" min-width="240">
          <template slot-scope="scope">{{scope.row.goodsName || scope.row.name}}</template>
        </el-table-column>
        <el-table-column key="t_goods_spec" label="商品规格" min-width="180">
          <template slot-scope="scope">{{$goodsSpecConvert(scope.row.specs || scope.row.spec)}}</template>
        </el-table-column>
      </template>
      <template v-else>
        <el-table-column key="t_category_name" label="分类名称" min-width="240">
          <template slot-scope="scope">{{scope.row.name}}</template>
        </el-table-column>
      </template>
      <template v-if="type === 'group'">
        <template v-if="method === 'discount'">
          <el-table-column key="m_discount" label="折扣" width="100">
            <template slot-scope="scope">{{scope.row.discount * 10}}折</template>
          </el-table-column>
        </template>
        <template v-else-if="method === 'price' && checkPermission(['PURCHASE_ALL','PURCHASE_TOTALMONEY'])">
          <el-table-column key="m_price" label="一口价" prop="price" width="100" :formatter="$price" />
        </template>
      </template>
      <el-table-column width="90" v-if="pickable">
        <div class="row-commands v c" slot-scope="scope">
          <el-button type="text" size="mini" @click="handleAdd(scope.row)">{{buttonText}}</el-button>
        </div>
      </el-table-column>
    </el-table>
    <!-- <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange"  /> -->
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import checkPermission from "@/utils/permission";

export default {
  mixins: [initData],
  props: {
    supplier: String | Number,
    type: String,
    method: String,
    store: Array,
    pickable: {
      type: Boolean,
      default: true,
    },
    buttonText: {
      type: String,
      default: "立即采购",
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.data = (this.store || []).map((o) => {
        let g = Object.assign({}, o);
        g.id = g.goodsId || g.targetId;
        delete g.targetId;
        return g;
      });
      return false;
    },
    handleAdd(goods) {
      this.$emit("submit", goods);
    },
  },
  mounted() {
    this.init();
  },
};
</script>