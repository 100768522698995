import request from '@/utils/request'

export function addAddress(data) {
  return request({
    url: 'api/distributor/address',
    method: 'post',
    data
  })
}

export function editAddress(data) {
  return request({
    url: 'api/distributor/address',
    method: 'put',
    data
  })
}

export function delAddress(id) {
  return request({
    url: 'api/distributor/address/' + id,
    method: 'delete'
  })
}

export function getDefaultAddress(distributorId) {
  return request({
    url: 'api/distributor/defaultAddress',
    method: 'get',
    params: { distributorId: distributorId }
  })
}

export function addContact(data) {
  return request({
    url: 'api/distributor/contact',
    method: 'post',
    data
  })
}

export function editContact(data) {
  return request({
    url: 'api/distributor/contact',
    method: 'put',
    data
  })
}

export function delContact(id) {
  return request({
    url: 'api/distributor/contact/' + id,
    method: 'delete'
  })
}

export function getDefaultContact(distributorId) {
  return request({
    url: 'api/distributor/defaultContact',
    method: 'get',
    params: { distributorId: distributorId }
  })
}