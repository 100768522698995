import request from "@/utils/request";

export function getByDistributor(distributorId) {
  return request({
    url: "api/capitalPool/distributor/" + distributorId,
    method: "get",
  });
}

export function getBySupplier(supplierId, erpId) {
  return request({
    url: "api/capitalPool/supply/" + supplierId,
    method: "get",
    params: { erpId: erpId },
  });
}

export function getStatementType() {
  return request({
    url: "api/capitalPool/type",
    method: "get",
  });
}

export function getStatementTypeBySupplier(supplierId) {
  return request({
    url: "api/capitalPool/type/" + supplierId,
    method: "get",
  });
}

export function getSet(supplierId, orderFormId) {
  let url = "api/rebateRec/getSet?sellerId=" + supplierId;
  if (orderFormId) {
    url += "&orderFormId=" + orderFormId;
  }
  return request({
    url,
    method: "get",
  });
}

export function getU9ByDistributor(distributorId, params) {
  return request({
    url: "api/u9/capitalPool/distributor/" + distributorId,
    method: "get",
    params,
  });
}
