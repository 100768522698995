<template>
  <div class="v" v-if="promotion">
    <template v-if="detail">
      <div class="padding-05-0 h">
        <div class="fc-g no-flex">活动类型：</div>
        <div class="flex" v-if="detail.joinType === 'goods'">采购商品促销，直接针对采购的商品的促销活动</div>
        <div class="flex" v-else-if="detail.joinType === 'wholeOrder'">整单优惠促销，针对整个订单的促销活动（订单中已经参加了其他促销活动的商品不能再参加整单优惠促销活动）</div>
      </div>
      <div class="padding-05-0 h">
        <div class="fc-g no-flex">活动时间：</div>
        <div class="flex">{{new Date(detail.beginTime).format()}}&nbsp;至&nbsp;{{new Date(detail.endTime).format()}}</div>
      </div>
      <div class="padding-05-0 h">
        <div class="fc-g no-flex">活动介绍：</div>
        <div class="flex">
          <div>{{detail.info || "暂无"}}</div>
          <div class="fc-e flex" v-if="detail.sublist.length > 1">注意事项：活动内容中每个活动项目的条件都必须达到后才可以参加对应活动项目。</div>
        </div>
      </div>

      <template v-if="promotion.joinType === 'goods'">
        <div class="h s">
          <div class="fc-g lh-150 no-flex">活动内容：</div>
          <div class="flex">
            <!-- <template v-if="detail.quantityLadder">
              <template v-for="(sub, si) in detail.sublist">
                <el-card :header="`活动项目${si + 1}`" shadow="hover" :key="sub.id" style="margin-bottom: 10px;">
                  <div v-for="(item, inx) in sub._items" :key="item._id">
                    <div class="__assembly_promotion_select_item h c" v-for="(l, li) in item._ladders" :key="l._id">
                      <div class="__assembly_promotion_select_no">{{li + 1}}</div>
                      <div class="flex">
                        <template v-if="detail.proCondition">
                          购买指定商品（任选其一或多个）金额达到
                          <b class="fc-e fs-large">{{$price(l.reachCondition)}}</b>
                        </template>
                        <template v-else>
                          购买指定商品（任选其一或多个）数量达到
                          <b class="fc-e fs-large">{{l.reachCondition}}</b>
                        </template>
                        <template v-if="detail.method === 'discount'">时可享受折扣优惠</template>
                        <template v-else-if="detail.method === 'save'">时享受单价直降优惠</template>
                        <template v-else-if="detail.method === 'price'">时享受单价一口价优惠</template>
                        <template v-else-if="detail.method === 'gift'">时可以获得赠品</template>

                        <template v-if="detail.method === 'gift'">
                          <div v-for="(r, inx) in l.giftRules" :key="r.id">
                            <span v-if="l.giftRules.length > 1">{{inx + 1}}.&nbsp;</span>
                            <template v-for="(g, gInx) in r.goodsList">
                              <span class="fc-p">{{g.name}}({{$goodsSpecConvert(g.specs)}})&nbsp;×&nbsp;{{g.quantity}}</span>
                              {{gInx < r.goodsList.length - 1 ? "、" : ""}}
                            </template>
                            <span v-if="r.goodsList.length > 1">中的任意一种</span>
                          </div>
                        </template>
                      </div>
                    </div>

                    <div class="fc-g">参与活动的商品列表：</div>
                    <promotion-sku-selector class="flex gap-1x" :supplier="supplier" :type="detail.proType" :method="detail.method" :store="calcGoodsStore(item)" :button-text="buttonText" :pickable="pickable" @submit="handleGoodsSelect" />
                  </div>
                </el-card>
              </template>
            </template>-->
            <!-- <template v-else> -->
            <template v-for="(sub, si) in detail.sublist">
              <el-card :header="`活动项目${si + 1}`" shadow="hover" :key="sub.id" style="margin-bottom: 10px;">
                <div v-for="(item, inx) in sub._items" :key="item._id">
                  <div v-for="(l, li) in item._ladders" :key="l._id" style="margin-bottom: 10px;">
                    <div class="padding-10 bc-l" style="border: #dfe6ec solid; border-width: 1px 1px 0 1px;">
                      <span v-if="sub._items.length > 1">条件{{inx + 1}}：</span>
                      <template v-if="detail.proCondition">
                        购买以下{{detail.proType === 'category' ? "分类中的" : ""}}商品（任选其一或多个）金额达到
                        <b class="fc-e fs-large">{{$price(l.reachCondition)}}</b>
                      </template>
                      <template v-else>
                        购买以下{{detail.proType === 'category' ? "分类中的" : ""}}商品（任选其一或多个）数量达到
                        <b class="fc-e fs-large">{{l.reachCondition}}</b>
                      </template>
                      <template v-if="detail.method === 'discount'">&nbsp;时可享受折扣优惠</template>
                      <template v-else-if="detail.method === 'save'">&nbsp;时享受单价直降优惠</template>
                      <template v-else-if="detail.method === 'price'">&nbsp;时享受单价一口价优惠</template>
                    </div>
                    <promotion-sku-selector class="flex" :supplier="supplier" :type="detail.proType" :method="detail.method" :store="l.rulesGoods" :pickable="detail.proType === 'group'" :button-text="buttonText" @submit="handleGoodsSelect" />
                    <!-- <div class="h wrap" v-else>
                      <el-tag v-for="c in l.rulesGoods" :key="c.id" style="margin: 5px 5px 0 0;">{{c.name}}</el-tag>
                    </div> -->

                    <div v-if="detail.method === 'gift' && inx === sub._items.length - 1" class="gap-1x lh-150">
                      <div>达到以上条件时，可以获得以下赠品：</div>
                      <div v-for="(r, inx) in l.giftRules" :key="r.id">
                        <span v-if="l.giftRules.length > 1">{{inx + 1}}.&nbsp;</span>
                        <template v-for="(g, gInx) in r.goodsList">
                          <span class="fc-p">{{g.name}}({{$goodsSpecConvert(g.specs)}})&nbsp;×&nbsp;{{g.quantity}}</span>
                          {{gInx < r.goodsList.length - 1 ? "、" : ""}}
                        </template>
                        <span v-if="r.goodsList.length > 1">中的任意一种</span>
                      </div>
                    </div>
                  </div>
                </div>
              </el-card>
              <!-- </template> -->
            </template>
          </div>
        </div>
      </template>
      <template v-else-if="promotion.joinType === 'wholeOrder'">
        <div class="h c">
          <div class="fc-g lh-150 no-flex">活动内容：</div>
          <div class="flex">
            <div class="__assembly_promotion_select_item h c" v-for="(item, inx) in detail._items" :key="item._id">
              <div class="__assembly_promotion_select_no" v-if="detail._items.length > 1">{{inx + 1}}</div>
              <div class="flex">
                <template v-if="detail.proCondition">
                  购买商品金额达到
                  <b class="fc-e fs-large">{{$price(item.reachCondition)}}</b>
                </template>
                <template v-else>
                  购买商品数量达到
                  <b class="fc-e fs-large">{{item.reachCondition}}</b>
                </template>
                <template v-if="detail.method === 'discount'">
                  &nbsp;时享受
                  <b class="fc-p fs-large">{{item.discount * 10}}</b>&nbsp;折的优惠。
                </template>
                <template v-else-if="detail.method === 'save'">
                  &nbsp;时享受单价直降
                  <b class="fc-p fs-large">{{$price(item.saveAmount)}}</b>&nbsp;的优惠。
                </template>
                <template v-else-if="detail.method === 'price'">
                  &nbsp;时享受单价一口价
                  <b class="fc-p fs-large">{{$price(item.price)}}</b>&nbsp;的优惠。
                </template>
                <template v-else-if="detail.method === 'gift'">&nbsp;时可以获得赠品：</template>

                <template v-if="detail.method === 'gift'">
                  <div v-for="(r, inx) in item.giftRules" :key="r.id">
                    <span v-if="item.giftRules.length > 1">{{inx + 1}}.&nbsp;</span>
                    <template v-for="(g, gInx) in r.goodsList">
                      <span class="fc-p">{{g.name}}({{$goodsSpecConvert(g.specs)}})&nbsp;×&nbsp;{{g.quantity}}</span>
                      {{gInx < r.goodsList.length - 1 ? "、" : ""}}
                    </template>
                    <span v-if="r.goodsList.length > 1">中的任意一种</span>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="h c padding-05-0">
          <div class="fc-g no-flex">活动商品：</div>
          <div class="flex" v-if="detail.goodsRange === 'all'">所有商品</div>
          <div class="flex" v-else>
            <div style="margin-bottom: 5px;" v-if="detail.goodsRangeSet === 'whitelist'">
              以下商品
              <b class="fc-p">可以</b>&nbsp;参加活动
            </div>
            <div style="margin-bottom: 5px;" v-else>
              除以下商品
              <b class="fc-e">不可以</b>&nbsp;参加活动外，其他商品都可参加
            </div>
            <promotion-sku-selector class="flex" :supplier="supplier" type="group" :store="detail.goodsRanges" :method="detail.method" :pickable="pickable && detail.goodsRangeSet === 'whitelist'" :button-text="buttonText" @submit="handleGoodsSelect" />
          </div>
        </div>
      </template>
    </template>
    <div class="padding-10-0 fc-g" v-else-if="loading">正在获取活动明细…</div>
  </div>
</template>

<script>
import { promotion } from "@/api/marketing";
import promotionSkuSelector from "./promotionSkuSelect";

export default {
  components: { promotionSkuSelector },
  props: {
    supplier: String | Number,
    buttonText: {
      type: String,
      default: "立即采购",
    },
    pickable: {
      type: Boolean,
      default: true,
    },
    promotion: Object,
  },
  data() {
    return {
      loading: false,
      detail: null,
    };
  },
  watch: {
    promotion: "loadDetail",
  },
  methods: {
    loadDetail() {
      this.detail = null;
      if (this.promotion) {
        this.loading = true;
        promotion
          .get(this.promotion.id)
          .then((res) => {
            if (res.joinType === "goods") {
              (res.sublist || []).forEach((sub) => {
                let itemArr = [],
                  itemObj = {};
                (sub.rules || []).forEach((ladder) => {
                  ladder._id = this.$uuid();
                  ladder._maxGiftRuleIndex = 0;
                  let item = itemObj[ladder.groupId];
                  if (!item) {
                    let maxInx = 0;
                    item = {
                      _id: this.$uuid(),
                      groupId: ladder.groupId,
                      rulesGoods: [],
                      giftRule: [],
                      _categories: [],
                      _categoryNames: [],
                      _ladders: [],
                    };

                    // if (res.proType === "group") {
                    //   item.rulesGoods = sub.rulesGoods.filter((g) => {
                    //     return g.groupId === ladder.groupId && g.ladderId === ladder.ladderId;
                    //   });
                    // } else {
                    //   sub.rulesGoods.forEach((g) => {
                    //     if (g.groupId === ladder.groupId) {
                    //       item._categories.push(g.targetId);
                    //       item._categoryNames.push(g.name);
                    //     }
                    //   });
                    // }

                    // if (
                    //   itemArr.length === 0 &&
                    //   res.method === "gift" &&
                    //   !res.quantityLadder
                    // ) {
                    //   item.giftRules = sub.giftRules.filter((gr) => {
                    //     return gr.groupId === item.groupId;
                    //   });
                    // }

                    itemObj[ladder.groupId] = item;
                    itemArr.push(item);
                  }

                  // if (res.proType === "group") {
                  ladder.rulesGoods = sub.rulesGoods.filter((g) => {
                    return (
                      g.groupId === ladder.groupId &&
                      g.ladderId === ladder.ladderId
                    );
                  });
                  // } else {
                  //   sub.rulesGoods.forEach((g) => {
                  //     if (
                  //       g.groupId === ladder.groupId &&
                  //       g.ladderId === ladder.ladderId
                  //     ) {
                  //       item._categories.push(g.targetId);
                  //       item._categoryNames.push(g.name);
                  //     }
                  //   });
                  // }

                  if (res.method === "gift") {
                    ladder.giftRules = res.quantityLadder
                      ? sub.giftRules.filter((gr) => {
                          return (
                            gr.groupId === ladder.groupId &&
                            gr.ladderId === ladder.ladderId
                          );
                        })
                      : sub.giftRules;
                  }
                  item._ladders.push(ladder);
                });

                sub._items = itemArr;
                console.log(sub._items);
                delete sub.rules;
                delete sub.rulesGoods;
                // delete sub.giftRules;
              });

              this.detail = res;
            } else if (res.joinType === "wholeOrder") {
              let obj = {},
                arrs = [];
              (res.wholeOrderDetails || []).forEach((o) => {
                if (o.displayNo > this.maxCombinationIndex)
                  this.maxCombinationIndex = o.displayNo;
                if (!obj.hasOwnProperty(o.groupId)) {
                  obj[o.groupId] = Object.assign({}, o, {
                    _id: this.$uuid(),
                    giftRules: (res.giftRules || []).filter((g) => {
                      return (
                        g.promotionId === res.id && g.groupId === o.groupId
                      );
                    }),
                  });
                  arrs.push(obj[o.groupId]);
                }
              });
              res._items = arrs;
              delete res.wholeOrderDetails;
              delete res.giftRules;
              this.detail = res;
            }
          })
          .finally((_) => {
            this.loading = false;
          });
      }
    },
    calcGoodsStore(item, ladder) {
      if (this.detail.proType === "group") {
        return ladder.rulesGoods;
      } else if (this.detail.proType === "category") {
        return item._categories;
      }
    },

    handleGoodsSelect(goods) {
      if (goods) {
        this.$emit("submit", [goods]);
      }
    },
  },
  mounted() {
    this.loadDetail();
  },
};
</script>

<style lang="less">
.__assembly_promotion_select_item {
  margin-bottom: 10px;
  line-height: 1.5;
}
.__assembly_promotion_select_no {
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 50%;
  background-color: #67c23a;
  color: #fff;
  margin-right: 12px;
  font-size: 12px;
}
</style>