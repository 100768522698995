import request from '@/utils/request'


export function getPurchaseGoodsPrice(params) {
  return request({
    url: 'api/purchaseGoods/price',
    method: 'get',
    params
  })
}
export function add(data) {
  return request({
    url: 'api/purchaseForm',
    method: 'post',
    data
  })
}

export function edit(data) {
  return request({
    url: 'api/purchaseForm',
    method: 'put',
    data
  })
}

export function addAndSubmit(data) {
  return request({
    url: "api/purchaseForm/new",
    method: "post",
    data
  })
}

export function saveAndSubmit(data) {
  return request({
    url: "api/purchaseForm/saveAndSubmit",
    method: "put",
    data
  })
}

export function del(id) {
  return request({
    url: 'api/purchaseForm/' + id,
    method: 'delete'
  })

}

export function submit(id) {
  return request({
    url: "api/purchaseForm/" + id,
    method: "put"
  })
}

export function get(id) {
  return request({
    url: "api/purchaseForm/" + id,
    method: "get"
  })
}